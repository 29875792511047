export const planets = [
    {
        id: 0,
        name: 'Меркурій',
        path: '/mercury',
        color: 'hsl(196, 83%, 93%)',
        sectionColor: 'hsl(194, 48%, 49%)',
    },
    {
        id: 1,
        name: 'Венера',
        path: '/venus',
        color: 'hsl(39, 88%, 73%)',
        sectionColor: 'hsl(33, 82%, 61%)',
    },
    {
        id: 2,
        name: 'Земля',
        path: '/earth',
        color: 'hsl(238, 99%, 66%)',
        sectionColor: 'hsl(263, 67%, 51%)',
    },
    {
        id: 3,
        name: 'Марс',
        path: '/mars',
        color: 'hsl(12, 100%, 64%)',
        sectionColor: 'hsl(10, 63%, 51%)',
    },
    {
        id: 4,
        name: 'Юпітер',
        path: '/jupiter',
        color: 'hsl(27, 75%, 70%)',
        sectionColor: 'hsl(2, 68%, 53%)',
    },
    {
        id: 5,
        name: 'Сатурн',
        path: '/saturn',
        color: 'hsl(40, 96%, 70%)',
        sectionColor: 'hsl(17, 73%, 46%)',
    },
    {
        id: 6,
        name: 'Уран',
        path: '/uranus',
        color: 'hsl(168, 82%, 67%)',
        sectionColor: 'hsl(169, 73%, 44%)',
    },
    {
        id: 7,
        name: 'Нептун',
        path: '/neptune',
        color: 'hsl(222, 95%, 63%)',
        sectionColor: 'hsl(222, 87%, 56%)',
    },
];
