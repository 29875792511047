import neptuneImage from '../../assets/images/planet-neptune.svg';
import neptuneInternalImage from '../../assets/images/planet-neptune-internal.svg';
import neptuneGeoImage from '../../assets/images/planet-neptune-geology.png';

export const data = {
    name: 'Нептун',
    overview: {
        content:
            'Нептун є восьмою і найвіддаленішою від Сонця планетою Сонця. У Сонячній системі це четверта за розміром планета за діаметром, третя за масою та найщільніша планета-гігант. Це в 17 разів більше маси Землі, масивніше, ніж її близький близнюк Уран.',
        source: 'https://en.wikipedia.org/wiki/Neptune',
        image: neptuneImage,
    },
    structure: {
        content:
            "Внутрішня будова Нептуна нагадує структуру Урана. Його атмосфера становить приблизно 5-10% його маси і простягається, можливо, на 10-20% шляху до ядра. Зростаючі концентрації метану, аміаку та води виявляються в нижніх регіонах.",
        source: 'https://en.wikipedia.org/wiki/Neptune#Internal_structure',
        image: neptuneInternalImage,
    },
    geology: {
        content:
            "Атмосфера Нептуна складається на 80% з водню і на 19% з гелію. Також присутня невелика кількість метану. Виразні смуги поглинання метану існують на довжинах хвиль понад 600 нм у червоній та інфрачервоній частині спектра.",
        source: 'https://en.wikipedia.org/wiki/Neptune#Atmosphere',
        image: neptuneImage,
        geo: neptuneGeoImage,
    },
    sectionColor: 'hsl(222, 87%, 56%)',
    desktopImgWidth: '450px',
    tabletImgWidth: '285px',
    mobileImgWidth: '173px',
    rotation: '16.08 Hours',
    revolution: '164.79 Years',
    radius: '24,622 KM',
    temperature: '-201°c',
};
