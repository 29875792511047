import marsImage from '../../assets/images/planet-mars.svg';
import marsInternalImage from '../../assets/images/planet-mars-internal.svg';
import marsGeoImage from '../../assets/images/planet-mars-geology.png';

export const data = {
    name: 'МАРС',
    overview: {
        content:
            'Марс є четвертою планетою від Сонця і другою за розміром планетою в Сонячній системі, за розміром перевищуючи лише Меркурій. Англійською мовою Марс носить ім’я римського бога війни і часто згадується як "Червона планета".',
        source: 'https://en.wikipedia.org/wiki/Mars',
        image: marsImage,
    },
    structure: {
        content:
            'Як і Земля, Марс диференціювався на щільне металеве ядро, покрите менш щільними матеріалами. Спочатку вчені визначили, що ядро принаймні частково рідке. Сучасні моделі його внутрішньої частини передбачають ядро, що складається переважно із заліза та нікелю з приблизно 16–17% сірки.',
        source: 'https://en.wikipedia.org/wiki/Mars#Internal_structure',
        image: marsInternalImage,
    },
    geology: {
        content:
            'Марс — це планета земної групи, поверхня якої складається з мінералів, що містять кремній і кисень, металів та інших елементів, які зазвичай утворюють гірські породи. Поверхня в основному складається з толеїтового базальту, хоча деякі частини більш багаті кремнеземом, ніж типовий базальт.',
        source: 'https://en.wikipedia.org/wiki/Mars#Surface_geology',
        image: marsImage,
        geo: marsGeoImage,
    },
    sectionColor: 'hsl(10, 63%, 51%)',
    desktopImgWidth: '336px',
    tabletImgWidth: '213px',
    mobileImgWidth: '129px',
    rotation: '1.03 Days',
    revolution: '1.88 Years',
    radius: '3,389.5 KM',
    temperature: '-28°c',
};
