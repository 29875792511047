import venusImage from '../../assets/images/planet-venus.svg';
import venusInternalImage from '../../assets/images/planet-venus-internal.svg';
import venusGeoImage from '../../assets/images/planet-venus-geology.png';

export const data = {
    name: 'Венера',
    overview: {
        content:
            "Венера - друга планета від Сонця. Вона названа на честь римської богині кохання та краси. Будучи найяскравішим після Місяця природним об’єктом на нічному небі Землі, Венера може відкидати тінь і в рідкісних випадках її можна побачити неозброєним оком серед білого дня.",
        source: 'https://en.wikipedia.org/wiki/Venus',
        image: venusImage,
    },
    structure: {
        content:
            'Подібність у розмірі та щільності між Венерою та Землею свідчить про те, що вони мають схожу внутрішню структуру: ядро, мантію та кору. Як і ядро ​​Землі, ядро ​​Венери, швидше за все, принаймні частково рідке, оскільки дві планети охолоджувалися приблизно з однаковою швидкістю.',
        source: 'https://en.wikipedia.org/wiki/Venus#Internal_structure',
        image: venusInternalImage,
    },
    geology: {
        content:
            'Значна частина поверхні Венери, здається, була сформована вулканічною діяльністю. На Венері в декілька разів більше вулканів, ніж на Землі, і налічує 167 великих вулканів діаметром понад 100 км (60 миль). Єдиним вулканічним комплексом такого розміру на Землі є Великий острів Гаваї.',
        source: 'https://en.wikipedia.org/wiki/Venus#Surface_geology',
        image: venusImage,
        geo: venusGeoImage,
    },
    sectionColor: 'hsl(33, 82%, 61%)',
    desktopImgWidth: '400px',
    tabletImgWidth: '253px',
    mobileImgWidth: '154px',
    rotation: '243 Days',
    revolution: '224.7 Days',
    radius: '6,051.8 KM',
    temperature: '471°c',
};
