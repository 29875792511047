import { Info, Item, Heading, Desc } from './InfoStyles';

const InfoContainer = ({ planetData }) => {
    const containerVariants = {
        hidden: {
            opacity: 0,
            y: 25,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { delay: 1.5, duration: 1.5 },
        },
        exit: {
            opacity: 0,
            y: 25,
            transition: { duration: 1 },
        },
    };

    const formatValue = (value) => {
        const [number, unit] = value.split(' ');
        const roundedNumber = Math.round(Number(number));

        // Если округлили до 1, то возвращаем 'DAY', иначе 'DAYS'
        return `${roundedNumber} ${roundedNumber === 1 ? unit.slice(0, -1) : unit}`;
    };

    const formatNumericValue = (value) => {
        const numericValue = parseFloat(value);
        return isNaN(numericValue) ? value : Math.round(numericValue);
    };

    return (
        <Info
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Item>
                <Heading>Час обертання</Heading>
                <Desc>{formatValue(planetData.rotation)}</Desc>
            </Item>
            <Item>
                <Heading>Час повного оберту</Heading>
                <Desc>{formatValue(planetData.revolution)}</Desc>
            </Item>
            <Item>
                <Heading>Радіус</Heading>
                <Desc>{planetData.radius}</Desc>
            </Item>
            <Item>
                <Heading>Середня температура</Heading>
                <Desc>{formatNumericValue(planetData.temperature)}°C</Desc>
            </Item>
        </Info>
    );
};

export default InfoContainer;
