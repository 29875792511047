import jupiterImage from '../../assets/images/planet-jupiter.svg';
import jupiterInternalImage from '../../assets/images/planet-jupiter-internal.svg';
import jupiterGeoImage from '../../assets/images/planet-jupiter-geology.png';

export const data = {
    name: 'Юпітер',
    overview: {
        content:
            'Юпітер — п\'ята планета від Сонця і найбільша в Сонячній системі. Це газовий гігант, маса якого в два з половиною рази перевищує масу всіх інших планет Сонячної системи, разом узятих, але менше однієї тисячної маси Сонця.',
        source: 'https://en.wikipedia.org/wiki/Jupiter',
        image: jupiterImage,
    },
    structure: {
        content:
            "Коли Юнона прибула в 2016 році, вона виявила, що Юпітер має дуже дифузне ядро, яке змішується з його мантією. Можливою причиною є удар планети масою приблизно в десять земних мас через кілька мільйонів років після формування Юпітера, який міг би порушити спочатку тверде ядро ​​Юпітера.",
        source: 'https://en.wikipedia.org/wiki/Jupiter#Internal_structure',
        image: jupiterInternalImage,
    },
    geology: {
        content:
            'Найвідомішою особливістю Юпітера є Велика червона пляма, постійний антициклональний шторм, розташований на 22° на південь від екватора. Відомо, що він існує принаймні з 1831 року, а можливо, з 1665 року.',
        source: 'https://en.wikipedia.org/wiki/Jupiter#Great_Red_Spot_and_other_vortices',
        image: jupiterImage,
        geo: jupiterGeoImage,
    },
    sectionColor: 'hsl(2, 68%, 53%)',
    desktopImgWidth: '582px',
    tabletImgWidth: '369px',
    mobileImgWidth: '224px',
    rotation: '9.93 Hours',
    revolution: '11.86 Years',
    radius: '69,911 KM',
    temperature: '-108°c',
};
