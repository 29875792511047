import mercuryImage from '../../assets/images/planet-mercury.svg';
import mercuryInternalImage from '../../assets/images/planet-mercury-internal.svg';
import mercuryGeoImage from '../../assets/images/planet-mercury-geology.png';

export const data = {
    name: 'Меркурій',
    overview: {
        content:
            "Меркурій — найменша планета Сонячної системи і найближча до Сонця. Її орбіта навколо Сонця займає 87,97 земних днів, найкоротший з усіх планет Сонця. Меркурій є однією з чотирьох планет земної групи в Сонячній системі і є кам’янистим тілом, подібним до Землі.",
        source: 'https://en.wikipedia.org/wiki/Mercury_(planet)',
        image: mercuryImage,
    },
    structure: {
        content:
            "Схоже, що Меркурій має тверду силікатну кору та мантію, що покриває твердий зовнішній шар ядра із сульфіду заліза, більш глибокий шар рідкого ядра та тверде внутрішнє ядро. Щільність планети є другою за величиною в Сонячній системі і становить 5,427 г/см3, лише трохи менше, ніж щільність Землі.",
        source: 'https://en.wikipedia.org/wiki/Mercury_(planet)#Internal_structure',
        image: mercuryInternalImage,
    },
    geology: {
        content:
            "Поверхня Меркурія за зовнішнім виглядом схожа на поверхню Місяця, демонструючи великі рівнини, схожі на кобили, і великі кратери, що вказує на те, що вона була геологічно неактивною протягом мільярдів років. Він більш неоднорідний, ніж Марс або Місяць.",
        source: 'https://en.wikipedia.org/wiki/Mercury_(planet)#Surface_geology',
        image: mercuryImage,
        geo: mercuryGeoImage,
    },
    sectionColor: 'hsl(194, 48%, 49%)',
    desktopImgWidth: '290px',
    tabletImgWidth: '184px',
    mobileImgWidth: '111px',
    rotation: '58.6 Days',
    revolution: '87.97 Days',
    radius: '2,439.7 KM',
    temperature: '430°c',
};
