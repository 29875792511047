import saturnImage from '../../assets/images/planet-saturn.svg';
import saturnInternalImage from '../../assets/images/planet-saturn-internal.svg';
import saturnGeoImage from '../../assets/images/planet-saturn-geology.png';

export const data = {
    name: 'Сатурн',
    overview: {
        content:
            'Сатурн — шоста планета від Сонця і друга за величиною в Сонячній системі після Юпітера. Це газовий гігант із середнім радіусом приблизно в дев\'ять з половиною разів більше земного. Він має лише одну восьму від середньої щільності Землі.',
        source: 'https://en.wikipedia.org/wiki/Saturn',
        image: saturnImage,
    },
    structure: {
        content:
            "Незважаючи на те, що Сатурн складається здебільшого з водню та гелію, більша частина маси Сатурна не знаходиться в газовій фазі, тому що водень стає неідеальною рідиною, коли густина перевищує 0,01 г/см3, що досягається при радіусі, що містить 99,9% маси Сатурна.",
        source: 'https://en.wikipedia.org/wiki/Saturn#Internal_structure',
        image: saturnInternalImage,
    },
    geology: {
        content:
            "Зовнішня атмосфера Сатурна містить 96,3% молекулярного водню і 3,25% гелію за об'ємом. Найвідомішою особливістю планети є її видатна система кілець, яка складається здебільшого з частинок льоду з меншою кількістю скелястих уламків і пилу.",
        source: 'https://en.wikipedia.org/wiki/Saturn#Atmosphere',
        image: saturnImage,
        geo: saturnGeoImage,
    },
    sectionColor: 'hsl(17, 73%, 46%)',
    desktopImgWidth: '666px',
    tabletImgWidth: '422px',
    mobileImgWidth: '256px',
    rotation: '10.8 Hours',
    revolution: '29.46 Years',
    radius: '58,232 KM',
    temperature: '-138°c',
};
