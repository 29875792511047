import earthImage from '../../assets/images/planet-earth.svg';
import earthInternalImage from '../../assets/images/planet-earth-internal.svg';
import earthGeoImage from '../../assets/images/planet-earth-geology.png';

export const data = {
    name: 'Земля',
    overview: {
        content:
            "Третя планета від Сонця і єдина відома планета, на якій є життя. Приблизно 29,2% поверхні Землі займає суша, решта 70,8% вкрито водою. Відстань Землі від Сонця, фізичні властивості та геологічна історія дозволили життю розвиватися та процвітати.",
        source: 'https://en.wikipedia.org/wiki/Earth',
        image: earthImage,
    },
    structure: {
        content:
            "Надра Землі, як і інших планет земної групи, поділяються на шари за своїми хімічними або фізичними (реологічними) властивостями. Зовнішній шар являє собою хімічно відмінну силікатну тверду кору, яка підстилається високов’язкою твердою мантією.",
        source: 'https://en.wikipedia.org/wiki/Earth#Internal_structure',
        image: earthInternalImage,
    },
    geology: {
        content:
            'Загальна площа поверхні Землі становить близько 510 млн км2. Континентальна кора складається з матеріалу меншої щільності, такого як магматичні породи граніт і андезит. Менш поширеним є базальт, більш щільна вулканічна порода, яка є основною складовою дна океану.',
        source: 'https://en.wikipedia.org/wiki/Earth#Surface',
        image: earthImage,
        geo: earthGeoImage,
    },
    sectionColor: 'hsl(263, 67%, 51%)',
    desktopImgWidth: '450px',
    tabletImgWidth: '285px',
    mobileImgWidth: '173px',
    rotation: '0.99 Days',
    revolution: '365.26 Days',
    radius: '6,371 KM',
    temperature: '16°c',
};
