import uranusImage from '../../assets/images/planet-uranus.svg';
import uranusInternalImage from '../../assets/images/planet-uranus-internal.svg';
import uranusGeoImage from '../../assets/images/planet-uranus-geology.png';

export const data = {
    name: 'Уран',
    overview: {
        content:
            'Уран — сьома планета від Сонця. Його назва є відсиланням до грецького бога неба Урана, згідно з грецькою міфологією, який був прадідом Ареса. Вона має третій за величиною планетарний радіус і четверту за величиною планетарну масу в Сонячній системі.',
        source: 'https://en.wikipedia.org/wiki/Uranus',
        image: uranusImage,
    },
    structure: {
        content:
            "Стандартна модель структури Урана полягає в тому, що він складається з трьох шарів: кам’яного (силікатного/залізо-нікелевого) ядра в центрі, крижаної мантії в середині та зовнішньої газоподібної воднево-гелієвої оболонки. Ядро відносно невелике, його маса становить лише 0,55 маси Землі.",
        source: 'https://en.wikipedia.org/wiki/Uranus#Internal_structure',
        image: uranusInternalImage,
    },
    geology: {
        content:
            "Склад атмосфери Урана відрізняється від його основної маси і складається в основному з молекулярного водню та гелію. Молярна частка гелію, тобто кількість атомів гелію в молекулі газу, у верхній тропосфері становить 0,15±0,03.",
        source: 'https://en.wikipedia.org/wiki/Uranus#Atmosphere',
        image: uranusImage,
        geo: uranusGeoImage,
    },
    sectionColor: 'hsl(169, 73%, 44%)',
    desktopImgWidth: '458px',
    tabletImgWidth: '290px',
    mobileImgWidth: '176px',
    rotation: '17.2 Hours',
    revolution: '84 Years',
    radius: '25,362 KM',
    temperature: '-195°c',
};
